import {
  AnnotationIcon,
  DatabaseIcon,
  DocumentIcon,
  FingerPrintIcon,
  LightningBoltIcon,
  ScaleIcon,
} from "@heroicons/react/outline";
import { Popover, Transition } from "@headlessui/react";

import EasyTrans from "../components/bundel/EasyTrans";
import { Fragment } from "react";
import Gallery from "../components/Gallery";
import PriceCard from "../components/landingpage/PriceCard";
import React from "react";
import UserService from "../services/UserService";
import { XIcon } from "@heroicons/react/outline";
import ccheartlogo from "./../assets/cc/heart.black.png";
import { useNavigate } from "react-router-dom";

const features = [
  {
    name: <EasyTrans>Landingpage.LandingpageFeature1Header</EasyTrans>,
    description: (
      <EasyTrans>Landingpage.LandingpageFeature1HeaderCaption</EasyTrans>
    ),
    icon: FingerPrintIcon,
  },
  {
    name: <EasyTrans>Landingpage.LandingpageFeature2Header</EasyTrans>,
    description: (
      <EasyTrans>Landingpage.LandingpageFeature2HeaderCaption</EasyTrans>
    ),
    icon: ScaleIcon,
  },
  {
    name: <EasyTrans>Landingpage.LandingpageFeature3Header</EasyTrans>,
    description: (
      <EasyTrans>Landingpage.LandingpageFeature3HeaderCaption</EasyTrans>
    ),
    icon: DocumentIcon,
  },
  {
    name: <EasyTrans>Landingpage.LandingpageFeature4Header</EasyTrans>,
    description: (
      <EasyTrans>Landingpage.LandingpageFeature4HeaderCaption</EasyTrans>
    ),
    icon: AnnotationIcon,
  },
  {
    name: <EasyTrans>Landingpage.LandingpageFeature5Header</EasyTrans>,
    description: (
      <EasyTrans>Landingpage.LandingpageFeature5HeaderCaption</EasyTrans>
    ),
    icon: DatabaseIcon,
  },
  {
    name: <EasyTrans>Landingpage.LandingpageFeature6Header</EasyTrans>,
    description: (
      <EasyTrans>Landingpage.LandingpageFeature6HeaderCaption</EasyTrans>
    ),
    icon: LightningBoltIcon,
  },
];

const LandingPage = () => {
  let navigate = useNavigate();
  // Somewhere in your code, e.g. inside a handler:

  //#region Views
  return (
    <div>
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-7xl mx-auto">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
              aria-hidden="true"
            >
              <polygon points="50,0 100,0 50,100 0,100" />
            </svg>

            <Popover>
              <div className="relative pt-6 px-4 sm:px-6 lg:px-8"></div>

              <Transition
                as={Fragment}
                enter="duration-150 ease-out"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="duration-100 ease-in"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Popover.Panel
                  focus
                  className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                >
                  <div className="rounded-lg shadow-md bg-white ring-1 ring-black ring-opacity-5 overflow-hidden">
                    <div className="px-5 pt-4 flex items-center justify-between">
                      <div>
                        <img
                          className="h-8 w-auto"
                          src="https://tailwindui.com/img/logos/workflow-mark-green-600.svg"
                          alt=""
                        />
                      </div>
                      <div className="-mr-2">
                        <Popover.Button className="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-red-500">
                          <span className="sr-only">Close main menu</span>
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </Popover.Button>
                      </div>
                    </div>
                  </div>
                </Popover.Panel>
              </Transition>
            </Popover>

            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                  <span className="block xl:inline">
                    <EasyTrans>Landingpage.LandingpageHeaderColor1</EasyTrans>
                  </span>{" "}
                  <span className="block text-fairblue-500 xl:inline">
                    <EasyTrans>Landingpage.LandingpageHeaderColor2</EasyTrans>
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  <EasyTrans>Landingpage.LandingpageHeaderCaption</EasyTrans>
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <button
                      onClick={() => {
                        if (!UserService.isLoggedIn()) {
                          UserService.doRegister();
                        } else {
                          navigate("/registerwork");
                        }
                      }}
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-fairblack bg-fairdarkgreen hover:bg-fairblue-500 hover:text-fairwhite md:py-4 md:text-lg md:px-10"
                    >
                      <EasyTrans>Landingpage.LandingpageButton1</EasyTrans>
                    </button>
                  </div>
                  <div className="mt-3 sm:mt-0 sm:ml-3">
                    <button
                      onClick={() => {
                        window.scrollTo({
                          top: document.getElementById("features").offsetTop,
                          behavior: "smooth",
                        });
                      }}
                      href="#features"
                      className="w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-medium rounded-md text-fairblack bg-fairbrightgreen hover:bg-fairblue-500 hover:text-fairwhite md:py-4 md:text-lg md:px-10"
                    >
                      <EasyTrans>Landingpage.LandingpageButton2</EasyTrans>
                    </button>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>

        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <Gallery></Gallery>
        </div>
      </div>
      <div id="features" className="py-12 bg-white">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-center">
            <h2 className="text-base text-fairblue-500 font-semibold tracking-wide uppercase">
              <EasyTrans>Landingpage.LandingpageFeatures</EasyTrans>
            </h2>
            <p className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl">
              <EasyTrans>Landingpage.LandingpageFeaturesHeader</EasyTrans>
            </p>
            <p className="mt-4 max-w-2xl text-xl text-gray-500 lg:mx-auto">
              <EasyTrans>Landingpage.LandingpageFeatureHeaderCaption</EasyTrans>
            </p>
          </div>
          <div className="mt-10">
            <dl className="space-y-10 md:space-y-0 md:grid md:grid-cols-2 md:gap-x-8 md:gap-y-10">
              {features.map((feature) => (
                <div key={feature.name.props.children} className="relative">
                  <dt>
                    <div className="absolute flex items-center justify-center h-12 w-12 rounded-full bg-fairblue-500 text-white">
                      <feature.icon className="h-6 w-6" aria-hidden="true" />
                    </div>
                    <p className="ml-16 text-lg leading-6 font-medium text-fairblue-500">
                      {feature.name}
                    </p>
                  </dt>
                  <dd className="mt-2 ml-16 text-base text-gray-500">
                    {feature.description}
                  </dd>
                </div>
              ))}
            </dl>
          </div>
        </div>
      </div>
      <div className="bg-fairdarkgreen">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex lg:items-center lg:justify-between">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight">
            <span className="block">
              <EasyTrans>Landingpage.LandingpageTryColor1</EasyTrans>
            </span>
            <span className="block text-fairblue-500">
              <EasyTrans>Landingpage.LandingpageTryColor2</EasyTrans>
            </span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <button
                onClick={() => UserService.doRegister()}
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-white bg-fairblue-500 hover:bg-green-700"
              >
                <EasyTrans>Landingpage.LandingpageTryButton1</EasyTrans>
              </button>
            </div>
            <div className="ml-3 inline-flex rounded-md shadow">
              <a
                href="/searchworks"
                className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium rounded-md text-green-600 bg-white hover:bg-green-50"
              >
                <EasyTrans>Landingpage.LandingpageTryButton2</EasyTrans>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="bg-fairwhite p-10 flex justify-center">
        <img className="h-40" src={ccheartlogo} alt="ccheartlogo" />
        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl sm:tracking-tight flex justify-center items-center">
          <span className="block px-3">
            <EasyTrans>Landingpage.CCAustria</EasyTrans>
          </span>
        </h2>
      </div>
      {
        <div className="bg-fairbrightgreen">
          <div className="max-w-7xl mx-auto flex flex-col items-center justify-between py-12 px-4 sm:px-6 lg:py-16 lg:px-8 lg:flex-row">
            <PriceCard
              price="0"
              storage="200 MB"
              nameLangKey="Landingpage.PriceCards.Free.Name"
              onClick={() => {
                navigate("/registerwork");
              }}
            ></PriceCard>
            <PriceCard
              price="36"
              storage="5 GB"
              nameLangKey="Landingpage.PriceCards.Standard.Name"
              goto="https://shop.fairkom.net/produkt/fairregister-basic/"
            ></PriceCard>
            <PriceCard
              price="144"
              storage="50 GB"
              nameLangKey="Landingpage.PriceCards.Expert.Name"
              goto="https://shop.fairkom.net/produkt/fairregister-pro/"
            ></PriceCard>
          </div>
        </div>
      }
    </div>
  );
  //#endregion
};

export default LandingPage;
